<template>
  <div>
    <div style="background-image: url('logo.png');background-repeat: no-repeat;background-size: contain;background-position:center; height: 10vw;width: 20vw;margin: auto"></div>
    <b-tabs class="mt-4" content-class="mt-3" align="center">
      <!-- Info Tab -->
      <b-tab title="Project Info" id="tabInfo" active>
        <h3>The Project</h3>
        <div class="flex-row" style="display: flex;margin: 0 10vw">
          This web platform has been developed in the context of a multidisciplinary project of the University of Milan titled “I generi musicali nell’era di Spotify
          – Costruzione sociale, fruizione computazionale e pratiche produttivo-distributive” (in English: “Music genres in the Spotify era – Social construction, computational fruition and productive-distributive practices”).
          Such a project, called SpotiGeM, combines sociological, musicological, and computer science skills in order to investigate the transformation of the concept of music genre operated by digital
          streaming services.
        </div>
        <hr>
        <h3>Team</h3>
        <div>
          <h5 style="margin-top: 1.5rem">Sociology</h5>
          <p>Alessandro Gandini</p>
          <h5 style="margin-top: 1.5rem">Musicology</h5>
          <p>Maurizio Corbella</p>
          <h5 style="margin-top: 1.5rem">Technology</h5>
          <p>Luca Andrea Ludovico<br/>
            Adriano Baratè<br/>
            Carlo Amante</p>
        </div>
        <a href="mailto:adriano.barate@unimi.it,luca.ludovico@unimi.it?subject=SpotiGeM%20Info" class="mt-2">
          <b-icon-envelope style="cursor: pointer"></b-icon-envelope>
        </a>
        <hr>
        <h3>References</h3>
        <div>
          <p>Baratè, A., Ludovico, L.A.: A Web Platform to Extract and Investigate Music Genre Labels in Spotify. In: SMC-22 (Sound and Music Computing) - <em>accepted</em></p>
        </div>
      </b-tab>
      <!-- User Tab -->
      <b-tab title="User" id="tabUser">
        <h3>User Info</h3>
        <div class="flex-row" style="display: flex;justify-content: space-evenly">
          <div style="width:33%"><h5>Name:</h5><p>{{ user.name }}</p></div>
          <div style="width:33%"><h5>Country:</h5><p>{{ user.country }}</p></div>
          <div style="width:33%"><h5>Followers:</h5><p>{{ user.followers }}</p></div>
        </div>
        <h3>Personal Playlists</h3>
        <div>
          <b-table :busy="isPlaylistsTableBusy" @row-clicked="onPlaylistSelected" striped small hover :items="playlistsPersonal" :fields="playlistsFields" style="cursor: pointer" >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(name)="data" class="align-middle">
              <b>{{ data.value }}</b>
            </template>
            <template #cell(numberOfTracks)="data">
              {{ data.value }}
            </template>
            <template #cell(id)="data" class="align-middle">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <h3>Featured Playlists</h3>
        <div>
          <b-table :busy="isPlaylistsTableBusy" @row-clicked="onPlaylistSelected" striped small hover :items="playlistsFeatured" :fields="playlistsFields" style="cursor: pointer" >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(name)="data" class="align-middle">
              <b>{{ data.value }}</b>
            </template>
            <template #cell(numberOfTracks)="data">
              {{ data.value }}
            </template>
            <template #cell(id)="data" class="align-middle">
              {{ data.value }}
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab title="Playlist" ref="tabPlaylist">
        <div class="w-75 m-auto">
          <b-input-group prepend="Search tracks by playlist ID" class="mt-3">
            <b-form-input v-on:keyup.enter="searchPlaylist" v-model="searchPlaylistId" type="search" placeholder="Playlist ID"></b-form-input>
            <b-input-group-append>
              <b-button @click="searchPlaylist">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div v-if="tracksInPlaylist != null && tracksInPlaylist.length > 0" >
          <h3 class="mt-5"><b>{{ selectedPlaylistName }}</b>{{ " (" + tracksInPlaylist.length + " tracks)"}}</h3>
          <b-button class="m-3" pill v-show="!isTracksInPlaylistTableBusy">
            <download-csv
                :fields = "csvTrackFields"
                :data = "tracksInPlaylist"
                :name = "selectedPlaylistName + '.csv'">
              Download CSV
            </download-csv>
          </b-button>
          <b-button class="m-3" pill v-show="!isTracksInPlaylistTableBusy" @click="goto('playlistCharts')">Go to charts</b-button>
          <div>
            <b-table :busy="isTracksInPlaylistTableBusy" striped small hover :items="tracksInPlaylist" :fields="tracksInPlaylistFields">
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(preview)="data">
                <audio :src="data.value" controls v-if="data.value !== ''"></audio>
              </template>
              <template #cell(title)="data" class="align-middle">
                <b>{{ data.value }}</b>
              </template>
              <template #cell(albumTitle)="data" class="align-middle">
                <b><span class="link" @click="searchAlbum(data.item.albumId)">{{ data.value }}</span></b>
              </template>
              <template #cell(image)="data">
                <b-img :src="data.value" fluid alt="Album image"></b-img>
              </template>
              <template #cell(audioFeatures)="data">
                <b-icon :icon="data.value ? (data.detailsShowing ? 'caret-up-fill' : 'caret-down-fill') : 'arrow-clockwise'"
                        :animation="data.value ? '' : 'spin'"
                        style="cursor: pointer;"
                        @click="data.toggleDetails"></b-icon>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="m-1">
                    <b-col class="text-sm-left"><b>Release Year: </b>{{ row.item.albumReleaseYear }}</b-col>
                    <b-col class="text-sm-left"><b>Duration: </b>{{ millisToMinutesAndSeconds(row.item.duration) }}</b-col>
                    <b-col class="text-sm-left"><b>Key: </b>{{ getKeyFromPC(row.item.key) }}</b-col>
                    <b-col class="text-sm-left"><b>Mode: </b>{{ row.item.mode === 0 ? 'minor' : 'Major' }}</b-col>
                    <b-col class="text-sm-left"><b>Time Signature: </b>{{ row.item.timeSignature }}</b-col>
                  </b-row>
                  <b-row class="m-1">
                    <b-col class="text-sm-left"><b>Acousticness: </b>{{ row.item.acousticness }}</b-col>
                    <b-col class="text-sm-left"><b>Danceability: </b>{{ row.item.danceability }}</b-col>
                    <b-col class="text-sm-left"><b>Energy: </b>{{ row.item.energy }}</b-col>
                    <b-col class="text-sm-left"><b>Instrumentalness: </b>{{ row.item.instrumentalness }}</b-col>
                    <b-col class="text-sm-left"><b>Liveness: </b>{{ row.item.liveness }}</b-col>
                  </b-row>
                  <b-row class="m-1">
                    <b-col class="text-sm-left"><b>Loudness (dB): </b>{{ row.item.loudness }}</b-col>
                    <b-col class="text-sm-left"><b>Speechiness: </b>{{ row.item.speechiness }}</b-col>
                    <b-col class="text-sm-left"><b>Valence: </b>{{ row.item.valence }}</b-col>
                    <b-col class="text-sm-left"><b>BPM: </b>{{ row.item.tempo }}</b-col>
                    <b-col class="text-sm-left"></b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </div>
          <b-container class="mb-5" v-show="!isTracksInPlaylistTableBusy">
            <h3 class="mt-5 mb-0" ref="playlistCharts">Charts</h3>
            <b-form-checkbox class="ms-3" v-model="graphDots" @change="drawCharts('tracksInPlaylist');drawCharts('tracksInAlbum')" switch>
              Dot Charts when applicable
            </b-form-checkbox>
            <div class="w-75 m-auto">
              <b-input-group prepend="Compare with another playlist" class="mt-3">
                <b-form-input list="list-playlists" @keyup.enter="searchPlaylistCompare" v-model="searchPlaylistIdCompare" type="search" placeholder="Playlist ID"></b-form-input>
                <datalist id="list-playlists">
                  <option :key="playlist.id" v-for="playlist in playlistsPersonal">{{ playlist.id + " [" + playlist.name + "]" }}</option>
                </datalist>
                <b-input-group-append>
                  <b-button @click="searchPlaylistCompare">Search</b-button>
                </b-input-group-append>
              </b-input-group>
              <div class="mt-2 justify-content-center" style="display: flex">
                <h5 style="color: #c0625e" class="mt-2 mr-3" v-if="tracksInPlaylistCompare != null && tracksInPlaylistCompare.length > 0"><b>{{ selectedPlaylistNameCompare }}</b>{{ " (" + tracksInPlaylistCompare.length + " tracks)"}}</h5>
                <b-button class="my-1" size="sm" pill v-show="tracksInPlaylistCompare != null && tracksInPlaylistCompare.length > 0">
                  <download-csv
                      :fields = "csvTrackFields"
                      :data = "tracksInPlaylistCompare"
                      :name = "selectedPlaylistNameCompare + '.csv'">
                    Download CSV
                  </download-csv>
                </b-button>
              </div>
            </div>

            <b-tabs class="mt-4" content-class="mt-3" align="center">
              <b-tab title="Genres" active>
                <div><svg id="chartGenres1" /></div>
                <div>
                  <b-form-checkbox class="ms-3" v-model="genres1Filter" @change="drawCharts('tracksInPlaylist')" switch>
                    Hide single genres
                    <b-icon-info-circle-fill id="tooltip-target-1"></b-icon-info-circle-fill>
                    <b-tooltip target="tooltip-target-1" triggers="hover">
                      When selected, only genres with count > 1 are shown
                    </b-tooltip>
                  </b-form-checkbox>
                  <b-form-checkbox class="ms-3" v-model="genresWeight1" @change="drawCharts('tracksInPlaylist')" switch>
                    Simple Weight Algorithm
                    <b-icon-info-circle-fill id="tooltip-target-2"></b-icon-info-circle-fill>
                    <b-tooltip target="tooltip-target-2" triggers="hover">
                      When selected, every genre in each track counts 1<br>
                      <i>When deselected, in each track every genre counts 1 / #genres per track</i>
                    </b-tooltip>
                  </b-form-checkbox>
                  <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartGenres1', selectedPlaylistName + '_genres1')">Save Chart</b-button>
                </div>
              </b-tab>
              <b-tab title="Keys">
                <div class="mb-3"><svg id="chartKeytracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartKeytracksInPlaylist', selectedPlaylistName + '_key')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Mode">
                <div class="mb-3"><svg id="chartModetracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartModetracksInPlaylist', selectedPlaylistName + '_mode')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Time Signature">
                <div class="mb-3"><svg id="chartTimeSignaturetracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartTimeSignaturetracksInPlaylist', selectedPlaylistName + '_timesignature')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Tempo">
                <div class="mb-3"><svg id="chartTempotracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartTempotracksInPlaylist', selectedPlaylistName + '_tempo')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Valence">
                <div class="mb-3"><svg id="chartValencetracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartValencetracksInPlaylist', selectedPlaylistName + '_valence')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Acousticness">
                <div class="mb-3"><svg id="chartAcousticnesstracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartAcousticnesstracksInPlaylist', selectedPlaylistName + '_acousticness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Danceability">
                <div class="mb-3"><svg id="chartDanceabilitytracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartDanceabilitytracksInPlaylist', selectedPlaylistName + '_danceability')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Energy">
                <div class="mb-3"><svg id="chartEnergytracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartEnergytracksInPlaylist', selectedPlaylistName + '_energy')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Loudness">
                <div class="mb-3"><svg id="chartLoudnesstracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartLoudnesstracksInPlaylist', selectedPlaylistName + '_loudness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Instrumentalness">
                <div class="mb-3"><svg id="chartInstrumentalnesstracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartInstrumentalnesstracksInPlaylist', selectedPlaylistName + '_instrumentalness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Speechiness">
                <div class="mb-3"><svg id="chartSpeechinesstracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartSpeechinesstracksInPlaylist', selectedPlaylistName + '_speechiness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Liveness">
                <div class="mb-3"><svg id="chartLivenesstracksInPlaylist" /></div>
                <b-button class="mx-1 mt-2" size="sm" pill @click="saveChart('chartLivenesstracksInPlaylist', selectedPlaylistName + '_liveness')">Save Chart</b-button>
              </b-tab>
            </b-tabs>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="Album" ref="tabAlbum">
        <div class="w-75 m-auto">
          <b-input-group prepend="Search tracks by album ID" class="mt-3">
            <b-form-input v-on:keyup.enter="searchAlbum(searchAlbumId)" v-model="searchAlbumId" type="search" placeholder="Album ID"></b-form-input>
            <b-input-group-append>
              <b-button @click="searchAlbum(searchAlbumId)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div v-if="tracksInAlbum != null" >
          <h3 class="mt-5"><b>{{ selectedAlbum.name }}</b>{{ " (" + tracksInAlbum.length + " tracks)"}}</h3>
          <div class="flex-row" style="display: flex; justify-content: space-evenly">
            <div><h5>Release Year:</h5><p>{{ selectedAlbum.year }}</p></div>
            <div><h5>Cover:</h5><b-img :src="selectedAlbum.image" fluid alt="Album image"></b-img></div>
          </div>
          <h3 class="mb-0">Tracks</h3>
          <b-button class="m-3" pill v-show="!isTracksInAlbumTableBusy">
            <download-csv
                :fields = "csvTrackFields"
                :data = "tracksInAlbum"
                :name = "selectedAlbum.name + '.csv'"
                v-show="!isTracksInAlbumTableBusy">
              Download CSV
            </download-csv>
          </b-button>
          <b-button class="m-3" pill v-show="!isTracksInAlbumTableBusy" @click="goto('albumCharts')">Go to charts</b-button>
          <div>
            <b-table :busy="isTracksInAlbumTableBusy" striped small hover :items="tracksInAlbum" :fields="tracksInAlbumFields">
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(preview)="data">
                <audio :src="data.value" controls v-if="data.value !== ''"></audio>
              </template>
              <template #cell(title)="data" class="align-middle">
                <b>{{ data.value }}</b>
              </template>
              <template #cell(audioFeatures)="data">
                <b-icon :icon="data.value ? (data.detailsShowing ? 'caret-up-fill' : 'caret-down-fill') : 'arrow-clockwise'"
                        :animation="data.value ? '' : 'spin'"
                        style="cursor: pointer;"
                        @click="data.toggleDetails"></b-icon>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="m-1">
                    <b-col class="text-sm-left"><b>Release Year: </b>{{ row.item.albumReleaseYear }}</b-col>
                    <b-col class="text-sm-left"><b>Duration: </b>{{ millisToMinutesAndSeconds(row.item.duration) }}</b-col>
                    <b-col class="text-sm-left"><b>Key: </b>{{ getKeyFromPC(row.item.key) }}</b-col>
                    <b-col class="text-sm-left"><b>Mode: </b>{{ row.item.mode === 0 ? 'minor' : 'Major' }}</b-col>
                    <b-col class="text-sm-left"><b>Time Signature: </b>{{ row.item.timeSignature }}</b-col>
                  </b-row>
                  <b-row class="m-1">
                    <b-col class="text-sm-left"><b>Acousticness: </b>{{ row.item.acousticness }}</b-col>
                    <b-col class="text-sm-left"><b>Danceability: </b>{{ row.item.danceability }}</b-col>
                    <b-col class="text-sm-left"><b>Energy: </b>{{ row.item.energy }}</b-col>
                    <b-col class="text-sm-left"><b>Instrumentalness: </b>{{ row.item.instrumentalness }}</b-col>
                    <b-col class="text-sm-left"><b>Liveness: </b>{{ row.item.liveness }}</b-col>
                  </b-row>
                  <b-row class="m-1">
                    <b-col class="text-sm-left"><b>Loudness (dB): </b>{{ row.item.loudness }}</b-col>
                    <b-col class="text-sm-left"><b>Speechiness: </b>{{ row.item.speechiness }}</b-col>
                    <b-col class="text-sm-left"><b>Valence: </b>{{ row.item.valence }}</b-col>
                    <b-col class="text-sm-left"><b>BPM: </b>{{ row.item.tempo }}</b-col>
                    <b-col class="text-sm-left"></b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </div>
          <b-container class="mb-5" v-show="!isTracksInAlbumTableBusy">
            <h3 class="mt-5 mb-0" ref="albumCharts">Charts</h3>
            <b-form-checkbox class="ms-3" v-model="graphDots" @change="drawCharts('tracksInPlaylist');drawCharts('tracksInAlbum')" switch>
              Dot Charts when applicable
            </b-form-checkbox>
            <b-tabs class="mt-4" content-class="mt-3" align="center">
              <b-tab title="Keys" active>
                <div class="mb-3"><svg id="chartKeytracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartKeytracksInAlbum', selectedPlaylistName + '_key')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Mode">
                <div class="mb-3"><svg id="chartModetracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartModetracksInAlbum', selectedPlaylistName + '_mode')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Time Signature">
                <div class="mb-3"><svg id="chartTimeSignaturetracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartTimeSignaturetracksInAlbum', selectedPlaylistName + '_timesignature')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Tempo">
                <div class="mb-3"><svg id="chartTempotracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartTempotracksInAlbum', selectedPlaylistName + '_tempo')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Valence">
                <div class="mb-3"><svg id="chartValencetracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartValencetracksInAlbum', selectedPlaylistName + '_valence')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Acousticness">
                <div class="mb-3"><svg id="chartAcousticnesstracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartAcousticnesstracksInAlbum', selectedPlaylistName + '_acousticness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Danceability">
                <div class="mb-3"><svg id="chartDanceabilitytracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartDanceabilitytracksInAlbum', selectedPlaylistName + '_danceability')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Energy">
                <div class="mb-3"><svg id="chartEnergytracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartEnergytracksInAlbum', selectedPlaylistName + '_energy')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Loudness">
                <div class="mb-3"><svg id="chartLoudnesstracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartLoudnesstracksInAlbum', selectedPlaylistName + '_loudness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Instrumentalness">
                <div class="mb-3"><svg id="chartInstrumentalnesstracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartInstrumentalnesstracksInAlbum', selectedPlaylistName + '_instrumentalness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Speechiness">
                <div class="mb-3"><svg id="chartSpeechinesstracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartSpeechinesstracksInAlbum', selectedPlaylistName + '_speechiness')">Save Chart</b-button>
              </b-tab>
              <b-tab title="Liveness">
                <div class="mb-3"><svg id="chartLivenesstracksInAlbum" /></div>
                <b-button class="mx-1" size="sm" pill @click="saveChart('chartLivenesstracksInAlbum', selectedPlaylistName + '_liveness')">Save Chart</b-button>
              </b-tab>
            </b-tabs>
          </b-container>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import JsonCSV from 'vue-json-csv';

export default {
  name: 'Ruffle',
  components: {
    'downloadCsv': JsonCSV
  },
  data: function() {
    return {
      CLIENT_ID: '61a9bb7d5c7b4b0993b2a4af35e647a3',
      //REDIRECT_URI: 'http://127.0.0.1:8080/',
      REDIRECT_URI: 'https://spotigem.lim.di.unimi.it/',
      AUTH_BASE_URL: 'https://accounts.spotify.com/authorize',
      API_ENDPOINT: 'https://api.spotify.com/v1',
      searchUserId : null,

      user: {
        id: "",
        name: "",
        followers: 0,
        country: "",
        uri: ""
      },
      csvTrackFields: ["id","uri","preview","title","genres","artists","artistsIds","albumTitle","albumId","image","albumReleaseYear","duration","key","mode","timeSignature","acousticness","danceability","energy","instrumentalness","liveness","loudness","speechiness","valence","tempo"],
      accessToken: null,
      fetchOptions: null,
      genres1Filter: false,
      genresWeight1: true,
      playlistsPersonal: [],
      playlistsFeatured: [],
      graphDots: false,
      isPlaylistsTableBusy: false,
      isTracksInAlbumTableBusy: false,
      searchPlaylistId: "",
      searchPlaylistIdCompare: "",
      searchAlbumId: "",
      playlistsFields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'numberOfTracks',
          label: 'Number of Tracks',
          sortable: true
        },
        {
          key: 'id',
          sortable: false,
          class: 'grayed-column'
        }
      ],
      selectedPlaylistName: null,
      selectedPlaylistNameCompare: null,
      selectedAlbum: {
        name: null,
        year: null,
        image: null,
      },
      tracksInAlbum: null,
      tracksInPlaylist: null,
      tracksInPlaylistCompare: null,
      isTracksInPlaylistTableBusy: true,
      tracksInPlaylistFields: [
        {
          key: 'title',
          tdClass: 'table-middle'
        },
        {
          key: 'artists',
          tdClass: 'table-middle'
        },
        {
          key: 'albumTitle',
          label: 'Album',
          tdClass: 'table-middle'
        },
        {
          key: 'genres',
          label: 'Genres',
          tdClass: 'table-middle'
        },
        {
          key: 'image',
          label: 'Album Cover',
          tdClass: 'table-middle'
        },
        {
          key: 'preview',
          label: 'Preview',
          tdClass: 'table-middle'
        },
        {
          key: 'audioFeatures',
          label: 'Audio Features',
          tdClass: 'table-middle'
        }
      ],
      tracksInAlbumFields: [
        {
          key: 'title',
          tdClass: 'table-middle'
        },
        {
          key: 'artists',
          tdClass: 'table-middle'
        },
        {
          key: 'genres',
          label: 'Genres',
          tdClass: 'table-middle'
        },
        {
          key: 'preview',
          label: 'Preview',
          tdClass: 'table-middle'
        },
        {
          key: 'audioFeatures',
          label: 'Audio Features',
          tdClass: 'table-middle'
        }
      ],
    };
  },
  created() {
    this.fetchPlaylists();
  },
  computed: {
  },
  methods: {
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
    async checkAuth() {
      const currentLocation = String(window.location).split("#")[1];
      const currentQueryParameters = new URLSearchParams(currentLocation);
      this.accessToken = currentQueryParameters.get('access_token');
      const scopes = "user-library-read playlist-read-private playlist-modify-public playlist-modify-private";
      const AUTH_URL = this.AUTH_BASE_URL + '?client_id=' + this.CLIENT_ID
          + '&response_type=token&redirect_uri=' + this.REDIRECT_URI
          + "&scope=" + encodeURIComponent(scopes);
      if (this.accessToken == null) {
        window.location.href = AUTH_URL;
      }
      this.fetchOptions = {
        headers: new Headers({
          'Authorization': `Bearer ${this.accessToken}`
        })
      };

      const query = this.API_ENDPOINT + "/me";
      const response = await this.getSpotify(query);
      if (!response.ok) {
        console.log(response.status);
        window.location.href = AUTH_URL;
      } else {
        const json = await response.json();
        if (json.error != null) {
          window.location.href = AUTH_URL;
        } else {
          // console.log(json);
          this.user.id = json.id;
          this.user.name = json.display_name;
          this.user.followers = json.followers.total;
          this.user.country = json.country;
          this.user.uri = json.uri;
        }
        this.saveDataUser();
      }
    },

    async fetchPlaylists() {
      await this.checkAuth();

      this.isPlaylistsTableBusy = true;
      const base = this;

      // Fetch personal playlists
      let query = this.API_ENDPOINT + "/me/playlists";

      this.playlistsPersonal = [];
      let playlists = this.playlistsPersonal;

      let response = await this.getSpotify(query);
      if (!response.ok) {
        console.log(response.status);
      } else {
        const json = await response.json();
        // console.log(json);
        for (const item of json.items) {
          let playlist = {
            id: item.id,
            collaborative: item.collaborative,
            description: item.description,
            followers: item.followers == undefined ? 0 : item.followers.total,
            name: item.name,
            owner: item.owner.display_name,
            public: item.public,
            uri: item.uri,
            iduser: this.user.id,
            numberOfTracks: item.tracks.total
          };
          playlists.push(playlist);
        }
        this.saveDataPlaylists(playlists);
      }

      // Fetch featured playlists
      query = this.API_ENDPOINT + "/browse/featured-playlists";

      this.playlistsFeatured = [];
      playlists = this.playlistsFeatured;

      response = await this.getSpotify(query);
      if (!response.ok) {
        console.log(response.status);
      } else {
        const json = await response.json();
        for (const item of json.playlists.items) {
          let playlist = {
            id: item.id,
            collaborative: item.collaborative,
            description: item.description,
            followers: item.followers == undefined ? 0 : item.followers.total,
            name: item.name,
            owner: item.owner.display_name,
            public: item.public,
            uri: item.uri,
            iduser: this.user.id,
            numberOfTracks: item.tracks.total
          };
          playlists.push(playlist);
        }
        this.saveDataPlaylists(playlists);
        base.isPlaylistsTableBusy = false;
      }
    },

    async onPlaylistSelected(playlist) {
      await this.checkAuth();

      if (playlist === null)
        return;

      const tabPlaylist = this.$refs["tabPlaylist"];
      tabPlaylist.activate();

      this.isTracksInPlaylistTableBusy = true;
      let playlistId = playlist.id;

      this.searchPlaylistId = playlistId;
      await this.fetchPlaylist(playlistId);
    },

    async searchPlaylist() {
      await this.checkAuth();

      this.isTracksInPlaylistTableBusy = true;
      await this.fetchPlaylist(this.searchPlaylistId);
    },

    async searchPlaylistCompare() {
      await this.checkAuth();

      this.isTracksInPlaylistTableBusy = true;
      await this.fetchPlaylistCompare(this.searchPlaylistIdCompare);
    },

    async searchAlbum(albumId) {
      if (albumId == null || albumId == undefined)
        albumId = this.searchAlbumId;

      await this.checkAuth();

      const tabAlbum = this.$refs["tabAlbum"];
      tabAlbum.activate();

      this.isTracksInAlbumTableBusy = true;
      this.searchAlbumId = albumId;
      await this.fetchAlbum(albumId);
    },

    async fetchPlaylist(playlistId) {
      if (playlistId == undefined)
        return;

      // Fetch playlist
      let query = this.API_ENDPOINT + `/playlists/${playlistId}`;

      this.tracksInPlaylist = [];
      this.selectedPlaylistName = await this.getPlaylistName(query);
      query = this.API_ENDPOINT + `/playlists/${playlistId}/tracks`;
      await this.addTracksFromPlaylist(query, this.tracksInPlaylist, playlistId);
      this.saveDataTracks();
    },

    async fetchPlaylistCompare(playlistId) {
      if (playlistId == undefined)
        return;

      if (playlistId.includes("[") && playlistId.includes("]"))
        playlistId = playlistId.substring(0, playlistId.indexOf("[") - 1);

      // Fetch playlist
      let query = this.API_ENDPOINT + `/playlists/${playlistId}`;

      this.tracksInPlaylistCompare = [];
      this.selectedPlaylistNameCompare = await this.getPlaylistName(query);
      query = this.API_ENDPOINT + `/playlists/${playlistId}/tracks`;
      await this.addTracksFromPlaylist(query, this.tracksInPlaylistCompare, playlistId);
      this.saveDataTracks();
    },

    async fetchAlbum(albumId) {
      if (albumId == undefined)
        return;

      // Fetch album
      let query = this.API_ENDPOINT + `/albums/${albumId}`;

      this.tracksInAlbum = [];
      await this.addTracksFromAlbum(query, this.tracksInAlbum);
      this.saveDataTracks();
    },

    async getPlaylistName(query) {
      let playlistName = null;
      if (query != null) {
        const response = await this.getSpotify(query);
        if (!response.ok) {
          console.log(response.status);
        } else {
          const json = await response.json();
          playlistName = json.name;
        }
      }
      return playlistName;
    },

    async addTracksFromPlaylist(query, tracks, playlistId) {
      if (query != null) {
        const response = await this.getSpotify(query);
        if (!response.ok) {
          console.log(response.status);
          tracks = null;
          this.drawCharts("tracksInPlaylist");
          this.isTracksInPlaylistTableBusy = false;
          return null;
        } else {
          const json = await response.json();

          let artistsIds = [];
          for (const item of json.items) {
            const jsonTrack = item.track;
            if (jsonTrack == null) {
              console.log(item);
              continue;
            }
            let artists = "";
            let artistsIdsInTrack = [];
            if (jsonTrack.artists != null) {
              artists = jsonTrack.artists.map(v => {
                return v.name;
              }).join(" / ");
              artistsIdsInTrack = jsonTrack.artists.map(v => {
                return v.id;
              });
              artistsIds.push(artistsIdsInTrack);
            }
            let albumId = "";
            let albumTitle = "";
            let albumReleaseYear = "";
            let albumImageUrl = "";
            if (jsonTrack.album != null) {
              // ID
              albumId = jsonTrack.album.id;
              // Title
              albumTitle = jsonTrack.album.name;
              // Release date
              albumReleaseYear = jsonTrack.album.release_date;
              if (albumReleaseYear.length >= 4)
                albumReleaseYear = albumReleaseYear.substr(0, 4);
              else
                albumReleaseYear = "";
              // Cover
              if (jsonTrack.album.images != null && jsonTrack.album.images.length > 0) {
                let size = Number.MAX_VALUE;
                for (const image of jsonTrack.album.images) {
                  if (image.height < size) {
                    size = image.height;
                    albumImageUrl = image.url;
                  }
                }
              }
            }

            let track = {
              id: jsonTrack.id,
              uri: jsonTrack.uri,
              preview: jsonTrack.preview_url,
              title: jsonTrack.name,
              genresArray: [],
              genres: null,
              artists: artists,
              artistsIds: artistsIdsInTrack,
              artistsArray: [],
              albumTitle: albumTitle,
              albumId: albumId,
              image: albumImageUrl,
              albumReleaseYear: albumReleaseYear,
              audioFeatures: false,
              duration: null,
              key: null,
              mode: null,
              timeSignature: null,
              acousticness: null,
              danceability: null,
              energy: null,
              instrumentalness: null,
              liveness: null,
              loudness: null,
              speechiness: null,
              valence: null,
              tempo: null,
              popularity: jsonTrack.popularity,
              playlistid: playlistId
            };
            tracks.push(track);
          }
          await this.addTracksFromPlaylist(json.next, tracks, playlistId);

          // Artists (to retrieve the genre)
          let artistsIdsToFetch = [];
          for (let i = 0; i < artistsIds.length; i++) {
            artistsIdsToFetch.push(artistsIds[i].join("%2C"));
            if ((i + 1) % 20 === 0 || i === artistsIds.length - 1) {
              const queryArtists = this.API_ENDPOINT + "/artists/?ids=" + artistsIdsToFetch.join("%2C");
              const responseArtists = await this.getSpotify(queryArtists);
              if (!responseArtists.ok) {
                console.log(responseArtists.status);
              } else {
                const jsonArtists = await responseArtists.json();
                for (const artist of jsonArtists.artists) {
                  for (const t of tracks) {
                    if (t.artistsIds.includes(artist.id)) {
                      t.artistsArray.push(artist);
                      artist.genres.forEach(g => t.genresArray.push(g))
                    }
                  }
                }
              }
              artistsIdsToFetch = [];
            }
            for (const t of tracks) {
              t.genresArray = t.genresArray.filter((v, i, a) => a.indexOf(v) === i);
              t.genres = t.genresArray.join(" | ");
            }
          }

          // Audio Features
          let trackIdsToFetch = [];
          for (let i = 0; i < tracks.length; i++) {
            trackIdsToFetch.push(tracks[i].id);
            if ((i + 1) % 100 === 0 || i === tracks.length - 1) {
              const queryAudioFeatures = this.API_ENDPOINT + "/audio-features/?ids=" + trackIdsToFetch.join("%2C");
              const responseAudioFeatures = await this.getSpotify(queryAudioFeatures);

              if (!responseAudioFeatures.ok) {
                console.log(responseAudioFeatures.status);
              } else {
                const jsonAudioFeatures = await responseAudioFeatures.json();
                for (const track of jsonAudioFeatures.audio_features) {
                  for (const t of tracks) {
                    if (t.id === track.id) {
                      t.audioFeatures = true;
                      t.duration = track.duration_ms;
                      t.key = track.key;
                      t.mode = track.mode;
                      t.timeSignature = track.time_signature;
                      t.acousticness = track.acousticness;
                      t.danceability = track.danceability;
                      t.energy = track.energy;
                      t.instrumentalness = track.instrumentalness;
                      t.liveness = track.liveness;
                      t.loudness = track.loudness;
                      t.speechiness = track.speechiness;
                      t.valence = track.valence;
                      t.tempo = track.tempo;
                    }
                  }
                }
              }
              trackIdsToFetch = [];
            }
          }
          this.drawCharts("tracksInPlaylist");
          this.isTracksInPlaylistTableBusy = false;
        }
      }
    },

    async addTracksFromAlbum(query, tracks) {
      if (query != null) {
        const response = await this.getSpotify(query);
        if (!response.ok) {
          console.log(response.status);
        } else {
          const json = await response.json();

          this.selectedAlbum.name = json.name;

          let artistsIds = [];
          for (const jsonTrack of json.tracks.items) {
            let artists = "";
            let artistsIdsInTrack = [];
            if (jsonTrack.artists != null) {
              artists = jsonTrack.artists.map(v => {
                return v.name;
              }).join(" / ");
              artistsIdsInTrack = jsonTrack.artists.map(v => {
                return v.id;
              });
              artistsIds.push(artistsIdsInTrack);
            }
            let albumId = "";
            let albumTitle = "";
            let albumReleaseYear = "";
            let albumImageUrl = "";
            // ID
            albumId = json.id;
            // Title
            albumTitle = json.name;
            // Release date
            albumReleaseYear = json.release_date;
            if (albumReleaseYear.length >= 4)
              albumReleaseYear = albumReleaseYear.substr(0, 4);
            else
              albumReleaseYear = "";
            this.selectedAlbum.year = albumReleaseYear;
            // Cover
            if (json.images != null && json.images.length > 0) {
              let size = Number.MAX_VALUE;
              for (const image of json.images) {
                if (image.height < size) {
                  size = image.height;
                  albumImageUrl = image.url;
                }
              }
            }
            this.selectedAlbum.image = albumImageUrl;

            let track = {
              id: jsonTrack.id,
              uri: jsonTrack.uri,
              preview: jsonTrack.preview_url,
              title: jsonTrack.track_number + ". " + jsonTrack.name,
              genresArray: [],
              genres: null,
              artists: artists,
              artistsIds: artistsIdsInTrack,
              artistsArray: [],
              albumTitle: albumTitle,
              albumId: albumId,
              image: albumImageUrl,
              albumReleaseYear: albumReleaseYear,
              audioFeatures: false,
              duration: null,
              key: null,
              mode: null,
              timeSignature: null,
              acousticness: null,
              danceability: null,
              energy: null,
              instrumentalness: null,
              liveness: null,
              loudness: null,
              speechiness: null,
              valence: null,
              tempo: null
            };
            tracks.push(track);
          }
          await this.addTracksFromAlbum(json.next, tracks);

          // Artists (to retrieve the genre)
          let artistsIdsToFetch = [];
          for (let i = 0; i < artistsIds.length; i++) {
            artistsIdsToFetch.push(artistsIds[i].join("%2C"));
            if ((i + 1) % 20 === 0 || i === artistsIds.length - 1) {
              const queryArtists = this.API_ENDPOINT + "/artists/?ids=" + artistsIdsToFetch.join("%2C");
              const responseArtists = await this.getSpotify(queryArtists);
              if (!responseArtists.ok) {
                console.log(responseArtists.status);
              } else {
                const jsonArtists = await responseArtists.json();
                for (const artist of jsonArtists.artists) {
                  for (const t of tracks) {
                    if (t.artistsIds.includes(artist.id)) {
                      t.artistsArray.push(artist);
                      artist.genres.forEach(g => t.genresArray.push(g))
                    }
                  }
                }
              }
              artistsIdsToFetch = [];
            }
            for (const t of tracks) {
              t.genresArray = t.genresArray.filter((v, i, a) => a.indexOf(v) === i);
              t.genres = t.genresArray.join(" | ");
            }
          }

          // Audio Features
          let trackIdsToFetch = [];
          for (let i = 0; i < tracks.length; i++) {
            trackIdsToFetch.push(tracks[i].id);
            if ((i + 1) % 100 === 0 || i === tracks.length - 1) {
              const queryAudioFeatures = this.API_ENDPOINT + "/audio-features/?ids=" + trackIdsToFetch.join("%2C");
              const responseAudioFeatures = await this.getSpotify(queryAudioFeatures);

              if (!responseAudioFeatures.ok) {
                console.log(responseAudioFeatures.status);
              } else {
                const jsonAudioFeatures = await responseAudioFeatures.json();
                for (const track of jsonAudioFeatures.audio_features) {
                  for (const t of tracks) {
                    if (t.id === track.id) {
                      t.audioFeatures = true;
                      t.duration = track.duration_ms;
                      t.key = track.key;
                      t.mode = track.mode;
                      t.timeSignature = track.time_signature;
                      t.acousticness = track.acousticness;
                      t.danceability = track.danceability;
                      t.energy = track.energy;
                      t.instrumentalness = track.instrumentalness;
                      t.liveness = track.liveness;
                      t.loudness = track.loudness;
                      t.speechiness = track.speechiness;
                      t.valence = track.valence;
                      t.tempo = track.tempo;
                    }
                  }
                }
              }
              trackIdsToFetch = [];
            }
          }
          this.drawCharts("tracksInAlbum");
          this.isTracksInAlbumTableBusy = false;
        }
      }
    },

    async fetchSpotify(query, fetchOptions) {
      let response = await fetch(query, fetchOptions);
      if (!response.ok && response.status === 429) {
        const msecs = 1100 * response.headers.get("Retry-After");
        await new Promise((resolve) => setTimeout(resolve, msecs));
        response = await fetch(query, fetchOptions);
        if (!response.ok && response.status === 429) {
          const msecs = 2200 * response.headers.get("Retry-After");
          await new Promise((resolve) => setTimeout(resolve, msecs));
          response = await fetch(query, fetchOptions);
        }
      }
      return response;
    },

    async getSpotify(query) {
      let fetchOptions = { ...this.fetchOptions };
      fetchOptions.method= "GET";
      const response = await this.fetchSpotify(query, fetchOptions);
      return response;
    },

    millisToMinutesAndSeconds(millis) {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    },

    getKeyFromPC(pc) {
      if (pc === 0)
        return "C";
      else if (pc === 1)
        return "C#/Db";
      else if (pc === 2)
        return "D";
      else if (pc === 3)
        return "D#/Eb";
      else if (pc === 4)
        return "E";
      else if (pc === 5)
        return "F";
      else if (pc === 6)
        return "F#/Gb";
      else if (pc === 7)
        return "G";
      else if (pc === 8)
        return "G#/Ab";
      else if (pc === 9)
        return "A";
      else if (pc === 10)
        return "A#/Bb";
      else if (pc === 11)
        return "B";
      else
        return "none";
    },

    saveChart(svgId, filename) {
      const a = require("save-svg-as-png");
      a.saveSvgAsPng(document.getElementById(svgId), filename  + ".png", {scale: 2});
    },

    drawCharts(tracksCollectionType) {
      let tracks = this[tracksCollectionType];
      const comparison = tracksCollectionType != "tracksInAlbum" && this.searchPlaylistIdCompare != null && this.searchPlaylistIdCompare !== "";
      let tracksComparison = null;
      if (comparison)
        tracksComparison = this[tracksCollectionType + "Compare"];

      const d3 = require("d3");
      drawBarChart("key", "0: C, 1: C#/Db, 2: D, 3: D#/Eb, 4: E, 5: F, 6: F#/Gb, 7: G, 8: G#,Ab, 9: A, 10: A#,Bb, 11: B", "#chartKey", comparison, 0, 11.2, false);
      drawModePieChart("mode", "0: minor, 1: Major", "#chartMode", comparison, null, null, false);
      drawBarChart("timeSignature", "beats", "#chartTimeSignature", comparison, null, null, false);

      if (this.graphDots)
        drawDotsChart("tempo", "BPM", "#chartTempo", comparison, null, null, true);
      else
        drawBarChart("tempo", "BPM", "#chartTempo", comparison, null, null, true);

      if (this.graphDots)
        drawDotsChart("valence", "0..1", "#chartValence", comparison, 0, 1, true);
      else
        drawBarChart("valence", "0..1", "#chartValence", comparison, 0, 1, true);

      if (this.graphDots)
        drawDotsChart("acousticness", "0..1", "#chartAcousticness", comparison, 0, 1, true);
      else
        drawBarChart("acousticness", "0..1", "#chartAcousticness", comparison, 0, 1, true);

      if (this.graphDots)
        drawDotsChart("danceability", "0..1", "#chartDanceability", comparison, 0, 1, true);
      else
        drawBarChart("danceability", "0..1", "#chartDanceability", comparison, 0, 1, true);

      if (this.graphDots)
        drawDotsChart("energy", "0..1", "#chartEnergy", comparison, 0, 1, true);
      else
        drawBarChart("energy", "0..1", "#chartEnergy", comparison, 0, 1, true);

      if (this.graphDots)
        drawDotsChart("loudness", "dB", "#chartLoudness", comparison, null, null, true);
      else
        drawBarChart("loudness", "dB", "#chartLoudness", comparison, null, null, true);

      if (this.graphDots)
        drawDotsChart("instrumentalness", "0..1", "#chartInstrumentalness", comparison, 0, 1, true);
      else
        drawBarChart("instrumentalness", "0..1", "#chartInstrumentalness", comparison, 0, 1, true);

      if (this.graphDots)
        drawDotsChart("speechiness", "0..1", "#chartSpeechiness", comparison, 0, 1, true);
      else
        drawBarChart("speechiness", "0..1", "#chartSpeechiness", comparison, 0, 1, true);

      if (this.graphDots)
        drawDotsChart("liveness", "0..1", "#chartLiveness", comparison, 0, 1, true);
      else
        drawBarChart("liveness", "0..1", "#chartLiveness", comparison, 0, 1, true);

      let genresData = new Map();
      let genresDataComparison = new Map();
      if (this.genresWeight1) {
        tracks.forEach(t => {
          t.genresArray.forEach(g => {
            if (genresData.has(g))
              genresData.set(g, genresData.get(g) + 1);
            else
              genresData.set(g, 1);
          });
        });
      } else {
        tracks.forEach(t => {
          const weight = 1 / t.genresArray.length;
          t.genresArray.forEach(g => {
            if (genresData.has(g))
              genresData.set(g, genresData.get(g) + weight);
            else
              genresData.set(g, weight);
          });
        });
      }
      if (comparison) {
        if (this.genresWeight1) {
          tracksComparison.forEach(t => {
            t.genresArray.forEach(g => {
              if (genresDataComparison.has(g))
                genresDataComparison.set(g, genresDataComparison.get(g) + 1);
              else
                genresDataComparison.set(g, 1);
            });
          });
        } else {
          tracksComparison.forEach(t => {
            const weight = 1 / t.genresArray.length;
            t.genresArray.forEach(g => {
              if (genresDataComparison.has(g))
                genresDataComparison.set(g, genresDataComparison.get(g) + weight);
              else
                genresDataComparison.set(g, weight);
            });
          });
        }
      }
      if (tracksCollectionType == "tracksInPlaylist")
        drawGenresChart(genresData, genresDataComparison, "#chartGenres1", this.genres1Filter, comparison);

      // drawBoxPlots("#chartBoxPlots", comparison);

      function drawBarChart(attribute, xAxisText, elementId, comparison, min, max, stats) {
        const data = tracks.map(x => x[attribute]);
        const mean = d3.mean(data);
        const stdDev = d3.deviation(data);
        if (stdDev == undefined)
          return;
        let bins = d3.bin().thresholds(30)(data);
        const width = 1100;
        const height = 600;
        const margin = ({top: 30, right: 35, bottom: 35, left: 45});

        if (!comparison) {
          const bandwidth = bins[0].x1 - bins[0].x0;
          if (min == null)
            min = bins[0].x0;
          min = Math.min(min, mean - stdDev);

          if (max == null)
            max = bins[bins.length - 1].x1;
          max = Math.max(max, mean + stdDev);

          let x = d3.scaleLinear()
              .domain([min, max]).nice()
              .range([margin.left, width - margin.right]);

          let y = d3.scaleLinear()
              .domain([0, d3.max(bins, d => d.length)]).nice()
              .range([height - margin.bottom, margin.top]);

          let xAxis;
          if (bins.length > 1)
            xAxis = g => g
                .attr("transform", `translate(${(x(bins[1].x0) - x(bins[0].x0)) / 2},${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));
          else
            xAxis = g => g
                .attr("transform", `translate(10,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));

          let yAxis = g => g
              .attr("transform", `translate(${margin.left},0)`)
              .call(d3.axisLeft(y).ticks(height / 40));

          const svg = d3.select(elementId + tracksCollectionType)
              .attr('width', width)
              .attr('height', height);

          svg.selectAll("*").remove();

          svg.append("g")
              .attr("fill", "steelblue")
              .selectAll("rect")
              .data(bins)
              .join("rect")
              .attr("x", d => x(d.x0) + 1)
              .attr("width", d => Math.max(2, x(d.x1) - x(d.x0) - 2))
              .attr("y", d => y(d.length))
              .attr("height", d => y(0) - y(d.length));

          svg.append("g")
              .call(xAxis)
              .append("text")
              .attr("fill", "black")
              .attr("transform", `translate(${width / 2}, 30)`)
              .text(xAxisText);

          svg.append("g")
              .call(yAxis);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height - margin.bottom + 0.6);

          if (stats) {
            svg.append("rect")
                .attr("fill", "#EEE")
                .attr("x", x(mean - stdDev + bandwidth / 2))
                .attr("width", x(mean + stdDev) - x(mean - stdDev))
                .attr("y", 3)
                .attr("height", margin.top - 6);
            svg.append('line')
                .style("stroke", "#AAA")
                .style("stroke-width", "1.1px")
                .attr("x1", x(mean + bandwidth / 2))
                .attr("y1", height - margin.bottom + 0.6)
                .attr("x2", x(mean + bandwidth / 2))
                .attr("y2", 3);
            svg.append("text")
                .attr("fill", "black")
                .attr("x", x(mean + bandwidth / 2) + 6)
                .attr("y", margin.top / 2 + 4)
                .html("&micro;: " + mean.toFixed(3) + ", &sigma;: " + stdDev.toFixed(3));
          }

          svg.selectAll("text")
              .style("font-size", "10px");
        } else { // comparison
          let dataComparison = tracksComparison.map(x => x[attribute]);
          const meanComparison = d3.mean(dataComparison);
          const stdDevComparison = d3.deviation(dataComparison);
          if (stdDevComparison == undefined)
            return;
          let binsComparison = d3.bin().thresholds(30)(dataComparison);
          const bandwidth = bins[0].x1 - bins[0].x0;
          const bandwidthComparison = binsComparison[0].x1 - binsComparison[0].x0;

          if (min == null)
            min = Math.min(bins[0].x0, binsComparison[0].x0);
          min = Math.min(min, mean - stdDev);
          min = Math.min(min, meanComparison - stdDevComparison);

          if (max == null)
            max = Math.max(bins[bins.length - 1].x1, binsComparison[binsComparison.length - 1].x1);
          max = Math.max(max, mean + stdDev);
          max = Math.max(max, meanComparison + stdDevComparison);

          let x = d3.scaleLinear()
              .domain([min, max]).nice()
              .range([margin.left, width - margin.right]);

          let y = d3.scaleLinear()
              .domain([0, d3.max(bins, d => d.length)]).nice()
              .range([height / 2 - margin.bottom, margin.top]);

          let yComparison = d3.scaleLinear()
              .domain([0, d3.max(binsComparison, d => d.length)]).nice()
              .range([height - margin.bottom, height / 2 + margin.top]);

          let xAxis;
          if (bins.length > 1)
            xAxis = g => g
                .attr("transform", `translate(${(x(bins[1].x0) - x(bins[0].x0)) / 2},${height / 2 - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));
          else
            xAxis = g => g
                .attr("transform", `translate(10,${height / 2 - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));

          let xAxisComparison;
          if (binsComparison.length > 1)
            xAxisComparison = g => g
                .attr("transform", `translate(${(x(binsComparison[1].x0) - x(binsComparison[0].x0)) / 2},${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));
          else
            xAxisComparison = g => g
                .attr("transform", `translate(10,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));

          let yAxis = g => g
              .attr("transform", `translate(${margin.left},0)`)
              .call(d3.axisLeft(y).ticks(height / 80));

          let yAxisComparison = g => g
              .attr("transform", `translate(${margin.left},0)`)
              .call(d3.axisLeft(yComparison).ticks(height / 80));

          const svg = d3.select(elementId + tracksCollectionType)
              .attr('width', width)
              .attr('height', height);

          svg.selectAll("*").remove();

          svg.append("g")
              .attr("fill", "steelblue")
              .selectAll("rect")
              .data(bins)
              .join("rect")
              .attr("x", d => x(d.x0) + 1)
              .attr("width", d => Math.max(2, x(d.x1) - x(d.x0) - 2))
              .attr("y", d => y(d.length))
              .attr("height", d => y(0) - y(d.length));

          svg.append("g")
              .attr("fill", "#c0625e")
              .selectAll("rect")
              .data(binsComparison)
              .join("rect")
              .attr("x", d => x(d.x0) + 1)
              .attr("width", d => Math.max(2, x(d.x1) - x(d.x0) - 2))
              .attr("y", d => yComparison(d.length))
              .attr("height", d => yComparison(0) - yComparison(d.length));

          svg.append("g")
              .call(xAxis)
              .append("text")
              .attr("fill", "black")
              .attr("transform", `translate(${width / 2}, 30)`)
              .text(xAxisText);

          svg.append("g")
              .call(xAxisComparison)
              .append("text")
              .attr("fill", "black")
              .attr("transform", `translate(${width / 2}, 30)`)
              .text(xAxisText);

          svg.append("g")
              .call(yAxis);

          svg.append("g")
              .call(yAxisComparison);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height / 2 - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height / 2 - margin.bottom + 0.6);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height - margin.bottom + 0.6);

          if (stats) {
            svg.append("rect")
                .attr("fill", "#EEE")
                .attr("x", x(mean - stdDev + bandwidth / 2))
                .attr("width", x(mean + stdDev) - x(mean - stdDev))
                .attr("y", 3)
                .attr("height", margin.top - 6);
            svg.append('line')
                .style("stroke", "#AAA")
                .style("stroke-width", "1.1px")
                .attr("x1", x(mean + bandwidth / 2))
                .attr("y1", height / 2 - margin.bottom + 0.6)
                .attr("x2", x(mean + bandwidth / 2))
                .attr("y2", 3);
            svg.append("text")
                .attr("fill", "black")
                .attr("x", x(mean + bandwidth / 2) + 6)
                .attr("y", margin.top / 2 + 4)
                .html("&micro;: " + mean.toFixed(3) + ", &sigma;: " + stdDev.toFixed(3));

            svg.append("rect")
                .attr("fill", "#EEE")
                .attr("x", x(meanComparison - stdDevComparison + bandwidthComparison / 2))
                .attr("width", x(meanComparison + stdDevComparison) - x(meanComparison - stdDevComparison))
                .attr("y", height / 2 + 3)
                .attr("height", margin.top - 6);
            svg.append('line')
                .style("stroke", "#AAA")
                .style("stroke-width", "1.1px")
                .attr("x1", x(meanComparison + bandwidthComparison / 2))
                .attr("y1", height - margin.bottom + 0.6)
                .attr("x2", x(meanComparison + bandwidthComparison / 2))
                .attr("y2", height / 2 + 3);
            svg.append("text")
                .attr("fill", "black")
                .attr("x", x(meanComparison + bandwidthComparison / 2) + 6)
                .attr("y", height / 2 + margin.top / 2 + 4)
                .html("&micro;: " + meanComparison.toFixed(3) + ", &sigma;: " + stdDevComparison.toFixed(3));
          }

          svg.selectAll("text")
              .style("font-size", "10px");
        }
      }

      function drawDotsChart(attribute, xAxisText, elementId, comparison, min, max, stats) {
        const data = tracks.map(x => x[attribute]);
        const mean = d3.mean(data);
        const stdDev = d3.deviation(data);
        if (stdDev == undefined)
          return;
        const width = 1100;
        const height = 600;
        const margin = ({top: 10, right: 25, bottom: 35, left: 45});

        if (!comparison) {
          if (min == null)
            min = d3.min(data);
          min = Math.min(min, mean - stdDev);
          if (max == null)
            max = d3.max(data);
          max = Math.max(max, mean + stdDev);

          let x = d3.scaleLinear()
              .domain([min, max]).nice()
              .range([margin.left, width - margin.right]);

          let y = d3.scaleLinear()
              .domain([0, 1]).nice()
              .range([height - margin.bottom, margin.top]);

          let xAxis = g => g
              .attr("transform", `translate(0,${height - margin.bottom})`)
              .call(d3.axisBottom(x).ticks(width / 80))
              .call(g => g.select(".domain").remove())

          const svg = d3.select(elementId + tracksCollectionType)
              .attr('width', width)
              .attr('height', height);

          svg.selectAll("*").remove();

          svg.append("g")
              .attr("stroke", "steelblue")
              .attr("stroke-opacity", 0.2)
              .selectAll("circle")
              .data(data)
              .join("circle")
              .attr("cx", d => x(d))
              .attr("cy", y(0.5))
              .attr("fill", "steelblue")
              .attr("r", 3);

          svg.append("g")
              .attr("stroke", "steelblue")
              .selectAll("line")
              .data(data)
              .join("line")
              .attr("x1", d => x(d))
              .attr("x2", d => x(d))
              .attr("y1", y(0.48))
              .attr("y2", y(0.52));

          svg.append("g")
              .call(xAxis)
              .append("text")
              .attr("fill", "black")
              .attr("transform", `translate(${width / 2}, 30)`)
              .text(xAxisText);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height - margin.bottom + 0.6);

          if (stats) {
            svg.append("rect")
                .attr("fill", "#EEE")
                .attr("x", x(mean - stdDev))
                .attr("width", x(mean + stdDev) - x(mean - stdDev))
                .attr("y", y(0.60))
                .attr("height", 24);
            svg.append('line')
                .style("stroke", "#AAA")
                .style("stroke-width", "1.1px")
                .attr("x1", x(mean))
                .attr("y1", height - margin.bottom + 0.6)
                .attr("x2", x(mean))
                .attr("y2", y(0.60));
            svg.append("text")
                .attr("fill", "black")
                .attr("x", x(mean) + 6)
                .attr("y", y(0.60) + 16)
                .html("&micro;: " + mean.toFixed(3) + ", &sigma;: " + stdDev.toFixed(3));
          }

          svg.selectAll("text")
              .style("font-size", "10px");
        } else { // comparison
          const dataComparison = tracksComparison.map(x => x[attribute]);
          const meanComparison = d3.mean(dataComparison);
          const stdDevComparison = d3.deviation(dataComparison);
          if (stdDevComparison == undefined)
            return;

          if (min == null)
            min = Math.min(d3.min(data), d3.min(dataComparison));
          min = Math.min(min, mean - stdDev);
          min = Math.min(min, meanComparison - stdDevComparison);
          if (max == null)
            max = Math.max(d3.max(data), d3.max(dataComparison));
          max = Math.max(max, mean + stdDev);
          max = Math.max(max, meanComparison + stdDevComparison);

          let x = d3.scaleLinear()
              .domain([min, max]).nice()
              .range([margin.left, width - margin.right]);

          let y = d3.scaleLinear()
              .domain([0, 1]).nice()
              .range([height - margin.bottom, margin.top]);

          let xAxis = g => g
              .attr("transform", `translate(0,${height - margin.bottom})`)
              .call(d3.axisBottom(x).ticks(width / 80))
              .call(g => g.select(".domain").remove())

          const svg = d3.select(elementId + tracksCollectionType)
              .attr('width', width)
              .attr('height', height);

          svg.selectAll("*").remove();

          svg.append("g")
              .attr("stroke", "steelblue")
              .attr("stroke-opacity", 0.2)
              .selectAll("circle")
              .data(data)
              .join("circle")
              .attr("cx", d => x(d))
              .attr("cy", y(0.7))
              .attr("fill", "steelblue")
              .attr("r", 3);

          svg.append("g")
              .attr("stroke", "steelblue")
              .selectAll("line")
              .data(data)
              .join("line")
              .attr("x1", d => x(d))
              .attr("x2", d => x(d))
              .attr("y1", y(0.68))
              .attr("y2", y(0.72));

          svg.append("g")
              .attr("stroke", "#c0625e")
              .attr("stroke-opacity", 0.2)
              .selectAll("circle")
              .data(dataComparison)
              .join("circle")
              .attr("cx", d => x(d))
              .attr("cy", y(0.3))
              .attr("fill", "#c0625e")
              .attr("r", 3);

          svg.append("g")
              .attr("stroke", "#c0625e")
              .selectAll("line")
              .data(dataComparison)
              .join("line")
              .attr("x1", d => x(d))
              .attr("x2", d => x(d))
              .attr("y1", y(0.28))
              .attr("y2", y(0.32));

          svg.append("g")
              .call(xAxis)
              .append("text")
              .attr("fill", "black")
              .attr("transform", `translate(${width / 2}, 30)`)
              .text(xAxisText);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height - margin.bottom + 0.6);

          if (stats) {
            svg.append("rect")
                .attr("fill", "#EEE")
                .attr("x", x(mean - stdDev))
                .attr("width", x(mean + stdDev) - x(mean - stdDev))
                .attr("y", y(0.80))
                .attr("height", 24);
            svg.append('line')
                .style("stroke", "#AAA")
                .style("stroke-width", "1.1px")
                .attr("x1", x(mean))
                .attr("y1", height - margin.bottom + 0.6)
                .attr("x2", x(mean))
                .attr("y2", y(0.80));
            svg.append("text")
                .attr("fill", "black")
                .attr("x", x(mean) + 6)
                .attr("y", y(0.80) + 16)
                .html("&micro;: " + mean.toFixed(3) + ", &sigma;: " + stdDev.toFixed(3));

            svg.append("rect")
                .attr("fill", "#EEE")
                .attr("x", x(meanComparison - stdDevComparison))
                .attr("width", x(meanComparison + stdDevComparison) - x(meanComparison - stdDevComparison))
                .attr("y", y(0.40))
                .attr("height", 24);
            svg.append('line')
                .style("stroke", "#AAA")
                .style("stroke-width", "1.1px")
                .attr("x1", x(meanComparison))
                .attr("y1", height - margin.bottom + 0.6)
                .attr("x2", x(meanComparison))
                .attr("y2", y(0.40));
            svg.append("text")
                .attr("fill", "black")
                .attr("x", x(meanComparison) + 6)
                .attr("y", y(0.40) + 16)
                .html("&micro;: " + meanComparison.toFixed(3) + ", &sigma;: " + stdDevComparison.toFixed(3));
          }

          svg.selectAll("text")
              .style("font-size", "10px");
        }
      }

      function drawModePieChart(attribute, xAxisText, elementId, comparison) {
        const originalData = tracks.map(x => x[attribute]);
        let data = new Map();
        originalData.forEach(d => {
          if (data.has(d))
            data.set(d, data.get(d) + 1);
          else
            data.set(d, 1);
        });
        data = Array.from(data, ([x, value]) => ({x, value}));

        const width = 1100;
        const height = 600;

        const svg = d3.select(elementId + tracksCollectionType)
            .attr('width', width)
            .attr('height', height);

        svg.selectAll("*").remove();

        const g = svg.append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        const radius = height / 2.5;

        const ordScale = d3.scaleOrdinal()
            .domain(data)
            .range(['#71a0c8', 'steelblue']);

        const pie = d3.pie().value(function (d) {
          return d.value;
        });

        const arc = g.selectAll("arc")
            .data(pie(data))
            .enter();

        const path = d3.arc()
            .outerRadius(radius)
            .innerRadius(2 * radius / 3);

        arc.append("path")
            .attr("d", path)
            .attr("fill", function (d) {
              return ordScale(d.data.x);
            });

        let label = d3.arc()
            .outerRadius(radius)
            .innerRadius(2 * radius / 3);

        arc.append("text")
            .attr("transform", function (d) {
              return "translate(" + label.centroid(d) + ")";
            })
            .style("text-anchor", "middle")
            .text(function (d) {
              const perc = (100 * d.data.value / originalData.length).toFixed(1);
              return d.data.x == 0 ? "minor (" + perc + "%)" : "Major (" + perc + "%)";
            });

        svg.selectAll("text")
            .style("font-size", "12px");

        if (comparison) {
          const originalDataComparison = tracksComparison.map(x => x[attribute]);
          let dataComparison = new Map();
          originalDataComparison.forEach(d => {
            if (dataComparison.has(d))
              dataComparison.set(d, dataComparison.get(d) + 1);
            else
              dataComparison.set(d, 1);
          });
          dataComparison = Array.from(dataComparison, ([x, value]) => ({x, value}));

          const ordScaleComparison = d3.scaleOrdinal()
              .domain(dataComparison)
              .range(['#bb7b77', '#c0625e']);

          const arcComparison = g.selectAll("arc")
              .data(pie(dataComparison))
              .enter();

          const pathComparison = d3.arc()
              .outerRadius(2 * radius / 3)
              .innerRadius(radius / 3);

          arcComparison.append("path")
              .attr("d", pathComparison)
              .attr("fill", function (d) {
                return ordScaleComparison(d.data.x);
              });

          let labelComparison = d3.arc()
              .outerRadius(2 * radius / 3)
              .innerRadius(radius / 3);

          arcComparison.append("text")
              .attr("transform", function (d) {
                return "translate(" + labelComparison.centroid(d) + ")";
              })
              .style("text-anchor", "middle")
              .text(function (d) {
                const perc = (100 * d.data.value / originalDataComparison.length).toFixed(1);
                return d.data.x == 0 ? "minor (" + perc + "%)" : "Major (" + perc + "%)";
              });

          svg.selectAll("text")
              .style("font-size", "12px");
        }
      }

      function drawGenresChart(data, dataComparison, elementId, filter, comparison) {
        if (!comparison) {
          data = Array.from(data, ([genre, value]) => ({genre, value}));
          if (filter)
            data = data.filter(d => d.value > 1);
          const width = 1100;
          const height = 600;
          const margin = ({top: 10, right: 25, bottom: 130, left: 35});

          let x = d3.scaleBand()
              .domain(data.map(d => d.genre))
              .range([margin.left, width - margin.right])
              .padding(0.2);

          let y = d3.scaleLinear()
              .domain([0, d3.max(data.map(d => d.value))]).nice()
              .range([height - margin.bottom, margin.top]);

          let xAxis = g => g
              .attr("transform", `translate(${0},${height - margin.bottom})`)
              .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
              .selectAll("text")
              .style("text-anchor", "end")
              .attr("dx", "-.8em")
              .attr("dy", ".15em")
              .attr("transform", function () {
                return "rotate(-60)";
              });

          let yAxis = g => g
              .attr("transform", `translate(${margin.left},0)`)
              .call(d3.axisLeft(y).ticks(height / 40));


          const svg = d3.select(elementId)
              .attr('width', width)
              .attr('height', height);

          svg.selectAll("*").remove();

          svg.selectAll("mybar")
              .data(data)
              .enter()
              .append("rect")
              .attr("x", d => x(d.genre))
              .attr("y", d => y(d.value))
              .attr("width", x.bandwidth())
              .attr("height", d => y(0) - y(d.value))
              .attr("fill", "steelblue");

          svg.append("g")
              .call(xAxis);

          svg.append("g")
              .call(yAxis);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height - margin.bottom + 0.6);

          svg.selectAll("text")
              .style("font-size", "10px");
        } else { // comparison
          data = Array.from(data, ([genre, value]) => ({genre, value}));
          if (filter)
            data = data.filter(d => d.value > 1);
          dataComparison = Array.from(dataComparison, ([genre, value]) => ({genre, value}));
          if (filter)
            dataComparison = dataComparison.filter(d => d.value > 1);

          const width = 1100;
          const height = 600;
          const margin = ({top: 10, right: 25, bottom: 130, left: 35});

          let x = d3.scaleBand()
              .domain(data.map(d => d.genre).concat(dataComparison.map(d => d.genre)))
              .range([margin.left, width - margin.right])
              .padding(0.2);

          let y = d3.scaleLinear()
              .domain([0, d3.max([d3.max(data.map(d => d.value)), d3.max(dataComparison.map(d => d.value))])]).nice()
              .range([height - margin.bottom, margin.top]);

          let xAxis = g => g
              .attr("transform", `translate(${0},${height - margin.bottom})`)
              .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
              .selectAll("text")
              .style("text-anchor", "end")
              .attr("dx", "-.8em")
              .attr("dy", ".15em")
              .attr("transform", function () {
                return "rotate(-60)";
              });

          let yAxis = g => g
              .attr("transform", `translate(${margin.left},0)`)
              .call(d3.axisLeft(y).ticks(height / 40));


          const svg = d3.select(elementId)
              .attr('width', width)
              .attr('height', height);

          svg.selectAll("*").remove();

          svg.selectAll("mybar")
              .data(data)
              .enter()
              .append("rect")
              .attr("x", d => x(d.genre))
              .attr("y", d => y(d.value))
              .attr("width", x.bandwidth() / 2)
              .attr("height", d => y(0) - y(d.value))
              .attr("fill", "steelblue");
          svg.selectAll("mybar")
              .data(dataComparison)
              .enter()
              .append("rect")
              .attr("x", d => x(d.genre) + x.bandwidth() / 2)
              .attr("y", d => y(d.value) - 10)
              .attr("width", x.bandwidth() / 2)
              .attr("height", d => y(0) - y(d.value) + 10)
              .attr("fill", "#c0625e");

          svg.append("g")
              .call(xAxis);

          svg.append("g")
              .call(yAxis);

          svg.append('line')
              .style("stroke", "black")
              .style("stroke-width", "1.1px")
              .attr("x1", margin.left)
              .attr("y1", height - margin.bottom + 0.6)
              .attr("x2", width - margin.right)
              .attr("y2", height - margin.bottom + 0.6);

          svg.selectAll("text")
              .style("font-size", "10px");
        }
      }

      //   function drawBoxPlots(elementId, comparison) {
      //     const attributes01 = ["tempo", "valence", "acousticness", "danceability", "energy", "loudness", "instrumentalness", "speechiness", "liveness" ];
      //     const dataTempo = tracks.map(x => x["tempo"]);
      //     const dataLoudness = tracks.map(x => x["loudness"]);
      //     let data01 = new Map();
      //     attributes01.forEach(a => data01.set(a, tracks.map(x => x[a])));
      //
      //     // ------- Tempo -------
      //     const statsTempo = d3.boxplotStats(dataTempo);
      //
      //     const height = 600;
      //     const margin = ({top: 10, right: 25, bottom: 25, left: 45});
      //     let width = 100;
      //     let offset = 0;
      //
      //     const svg = d3.select(elementId + tracksCollectionType)
      //         .attr('width', width)
      //         .attr('height', height);
      //
      //     svg.selectAll("*").remove();
      //
      //     if (!comparison) {
      //       let minTempo = d3.min(dataTempo);
      //       let maxTempo = d3.max(dataTempo);
      //
      //       let x = d3.scaleLinear()
      //           .domain([min, max]).nice()
      //           .range([margin.left, width - margin.right]);
      //
      //       let y = d3.scaleLinear()
      //           .domain([0, 1]).nice()
      //           .range([height - margin.bottom, margin.top]);
      //
      //       let xAxis = g => g
      //           .attr("transform", `translate(0,${height - margin.bottom})`)
      //           .call(d3.axisBottom(x).ticks(width / 80))
      //           .call(g => g.select(".domain").remove())
      //
      //
      //       svg.append("g")
      //           .attr("stroke", "steelblue")
      //           .attr("stroke-opacity", 0.2)
      //           .selectAll("circle")
      //           .data(data)
      //           .join("circle")
      //           .attr("cx", d => x(d))
      //           .attr("cy", y(0.5))
      //           .attr("fill", "steelblue")
      //           .attr("r", 3);
      //
      //       svg.append("g")
      //           .attr("stroke", "steelblue")
      //           .selectAll("line")
      //           .data(data)
      //           .join("line")
      //           .attr("x1", d => x(d))
      //           .attr("x2", d => x(d))
      //           .attr("y1", y(0.48))
      //           .attr("y2", y(0.52));
      //
      //       svg.append("g")
      //           .call(xAxis)
      //           .append("text")
      //           .attr("fill", "black")
      //           .attr("transform",`translate(${width / 2}, 30)`)
      //           .text(xAxisText);
      //
      //       svg.append('line')
      //           .style("stroke", "black")
      //           .style("stroke-width", "1.1px")
      //           .attr("x1", margin.left)
      //           .attr("y1", height - margin.bottom + 0.6)
      //           .attr("x2", width - margin.right)
      //           .attr("y2", height - margin.bottom + 0.6);
      //
      //       if (stats) {
      //         svg.append("rect")
      //             .attr("fill", "#EEE")
      //             .attr("x", x(mean - stdDev))
      //             .attr("width", x(mean + stdDev) - x(mean - stdDev))
      //             .attr("y", y(0.60))
      //             .attr("height", 24);
      //         svg.append('line')
      //             .style("stroke", "#AAA")
      //             .style("stroke-width", "1.1px")
      //             .attr("x1", x(mean))
      //             .attr("y1", height - margin.bottom + 0.6)
      //             .attr("x2", x(mean))
      //             .attr("y2", y(0.60));
      //         svg.append("text")
      //             .attr("fill", "black")
      //             .attr("x", x(mean) + 6)
      //             .attr("y", y(0.60) + 16)
      //             .html("&micro;: " + mean.toFixed(3) + ", &sigma;: " + stdDev.toFixed(3));
      //       }
      //
      //       svg.selectAll("text")
      //           .style("font-size","10px");
      //     } else { // comparison
      //       const dataComparison = tracksComparison.map(x => x[attribute]);
      //       const meanComparison = d3.mean(dataComparison);
      //       const stdDevComparison = d3.deviation(dataComparison);
      //       if (stdDevComparison == undefined)
      //         return;
      //
      //       if (min == null)
      //         min = Math.min(d3.min(data), d3.min(dataComparison));
      //       min = Math.min(min, mean - stdDev);
      //       min = Math.min(min, meanComparison - stdDevComparison);
      //       if (max == null)
      //         max = Math.max(d3.max(data), d3.max(dataComparison));
      //       max = Math.max(max, mean + stdDev);
      //       max = Math.max(max, meanComparison + stdDevComparison);
      //
      //       let x = d3.scaleLinear()
      //           .domain([min, max]).nice()
      //           .range([margin.left, width - margin.right]);
      //
      //       let y = d3.scaleLinear()
      //           .domain([0, 1]).nice()
      //           .range([height - margin.bottom, margin.top]);
      //
      //       let xAxis = g => g
      //           .attr("transform", `translate(0,${height - margin.bottom})`)
      //           .call(d3.axisBottom(x).ticks(width / 80))
      //           .call(g => g.select(".domain").remove())
      //
      //       const svg = d3.select(elementId + tracksCollectionType)
      //           .attr('width', width)
      //           .attr('height', height);
      //
      //       svg.selectAll("*").remove();
      //
      //       svg.append("g")
      //           .attr("stroke", "steelblue")
      //           .attr("stroke-opacity", 0.2)
      //           .selectAll("circle")
      //           .data(data)
      //           .join("circle")
      //           .attr("cx", d => x(d))
      //           .attr("cy", y(0.7))
      //           .attr("fill", "steelblue")
      //           .attr("r", 3);
      //
      //       svg.append("g")
      //           .attr("stroke", "steelblue")
      //           .selectAll("line")
      //           .data(data)
      //           .join("line")
      //           .attr("x1", d => x(d))
      //           .attr("x2", d => x(d))
      //           .attr("y1", y(0.68))
      //           .attr("y2", y(0.72));
      //
      //       svg.append("g")
      //           .attr("stroke", "#c0625e")
      //           .attr("stroke-opacity", 0.2)
      //           .selectAll("circle")
      //           .data(dataComparison)
      //           .join("circle")
      //           .attr("cx", d => x(d))
      //           .attr("cy", y(0.3))
      //           .attr("fill", "#c0625e")
      //           .attr("r", 3);
      //
      //       svg.append("g")
      //           .attr("stroke", "#c0625e")
      //           .selectAll("line")
      //           .data(dataComparison)
      //           .join("line")
      //           .attr("x1", d => x(d))
      //           .attr("x2", d => x(d))
      //           .attr("y1", y(0.28))
      //           .attr("y2", y(0.32));
      //
      //       svg.append("g")
      //           .call(xAxis)
      //           .append("text")
      //           .attr("fill", "black")
      //           .attr("transform",`translate(${width / 2}, 30)`)
      //           .text(xAxisText);
      //
      //       svg.append('line')
      //           .style("stroke", "black")
      //           .style("stroke-width", "1.1px")
      //           .attr("x1", margin.left)
      //           .attr("y1", height - margin.bottom + 0.6)
      //           .attr("x2", width - margin.right)
      //           .attr("y2", height - margin.bottom + 0.6);
      //
      //       if (stats) {
      //         svg.append("rect")
      //             .attr("fill", "#EEE")
      //             .attr("x", x(mean - stdDev))
      //             .attr("width", x(mean + stdDev) - x(mean - stdDev))
      //             .attr("y", y(0.80))
      //             .attr("height", 24);
      //         svg.append('line')
      //             .style("stroke", "#AAA")
      //             .style("stroke-width", "1.1px")
      //             .attr("x1", x(mean))
      //             .attr("y1", height - margin.bottom + 0.6)
      //             .attr("x2", x(mean))
      //             .attr("y2", y(0.80));
      //         svg.append("text")
      //             .attr("fill", "black")
      //             .attr("x", x(mean) + 6)
      //             .attr("y", y(0.80) + 16)
      //             .html("&micro;: " + mean.toFixed(3) + ", &sigma;: " + stdDev.toFixed(3));
      //
      //         svg.append("rect")
      //             .attr("fill", "#EEE")
      //             .attr("x", x(meanComparison - stdDevComparison))
      //             .attr("width", x(meanComparison + stdDevComparison) - x(meanComparison - stdDevComparison))
      //             .attr("y", y(0.40))
      //             .attr("height", 24);
      //         svg.append('line')
      //             .style("stroke", "#AAA")
      //             .style("stroke-width", "1.1px")
      //             .attr("x1", x(meanComparison))
      //             .attr("y1", height - margin.bottom + 0.6)
      //             .attr("x2", x(meanComparison))
      //             .attr("y2", y(0.40));
      //         svg.append("text")
      //             .attr("fill", "black")
      //             .attr("x", x(meanComparison) + 6)
      //             .attr("y", y(0.40) + 16)
      //             .html("&micro;: " + meanComparison.toFixed(3) + ", &sigma;: " + stdDevComparison.toFixed(3));
      //       }
      //
      //       svg.selectAll("text")
      //           .style("font-size","10px");
      //     }
      //   }
      // },
    },

    saveDataUser() {
      const query = this.REDIRECT_URI + `/savedatauser.php`;
      let fetchOptions = {};
      fetchOptions.method = "POST";
      fetchOptions.headers = new Headers({
        'Content-Type': 'application/json'
      });
      fetchOptions.body = JSON.stringify(this.user);
      fetch(query, fetchOptions);
    },
    saveDataPlaylists(playlists) {
      const query = this.REDIRECT_URI + `/savedataplaylists.php`;
      let fetchOptions = {};
      fetchOptions.method = "POST";
      fetchOptions.headers = new Headers({
        'Content-Type': 'application/json'
      });
      fetchOptions.body = JSON.stringify(playlists);
      fetch(query, fetchOptions);
    },
    saveDataTracks() {
      const query = this.REDIRECT_URI + `/savedatatracks.php`;
      let fetchOptions = {};
      fetchOptions.method = "POST";
      fetchOptions.headers = new Headers({
        'Content-Type': 'application/json'
      });
      fetchOptions.body = JSON.stringify(this.tracksInPlaylist);
      fetch(query, fetchOptions);
    },
  },
}
</script>

<style>
h3 {
  margin: 30px 0 10px 0 !important;
}
a {
  color: #42b983;
}
.table-middle {
  vertical-align: middle !important;
}
.grayed-column {
  color: #AAA;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
